html,body {
  padding: 0;
  margin: 0;
  background-color: #091430;
  color: white;
}


.App {
  height: calc(100vh - 50px);
  width: 100%;
}

.header {
  color: white;
  padding: 5px 20px;
  font-size: 0.5em;
  display: flex;
  justify-content: space-between
}

.explain {
  color: #091430;
  margin-left: 30px;
  font-size: 14px;
  background-color: white;
  padding: 5px 10px;
  border-radius: 5px;
}

@media screen and (max-width: 700px) {
  .explain{
    display: none;
  }
}

.loader {
  width: 100vw;
  min-height: 80vh;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  justify-content: center;
}
.circle {
  background-color: #0078d4;
  border-radius: 50%;
  color: #fff;
  height: 1.5em;
  /* position: relative; */
  width: 1.5em;
  border: 3px solid #fff;
}

.circle:hover {
  background-color: #d83b01;
}


.hover {
  position: absolute;
  background-color: #d83b01;
  border: 3px solid white;
  z-index: 9999999999999999;
  left: -0.25em;
  top: -0.25em;
  height: 2em;
  width: 2em;
}

.tooltip {
  z-index: 999999999;
  position: absolute;
  background-color: #091430;
  color: white;
  top: 0px;
  left: 30px;
  min-width: 400px;
  border-radius: 10px;
  padding: 20px;
  text-align: center;
}

.tooltip h1 {
  margin: 0;
  padding: 0;
  margin-bottom: 15px;
}
.tooltip table {
  text-align: left;
  width: 100%;
}

.tooltip table tr td {
  padding: 5px 10px;
}
.tooltip table tr td:nth-child(1){
  font-size: 1.3em;
}

.tooltip table tr td:nth-child(2){
  background-color: #fff;
  color: #091430;
  font-size: 1.5em;
}
.tooltip-hide {
  position: absolute;
  background-color: #fff;
  color: black;
  top: 40px;
  min-width: 100px;
  padding: 10px;
  display:none;
}




.pin2 {
  position: absolute;
  top: 0%;
  left: 0%;
  margin-left: 0px;
  bottom: -8px;
  
  border-radius: 50%;
  border: 8px solid #0078d4;
  width: 8px;
  height: 8px;
}

.pin2::after {
  position: absolute;
  content: '';
  width: 0px;
  height: 0px;
  bottom: -30px;
  left: -6px;
  border: 10px solid transparent;
  border-top: 17px solid #0078d4;
}

