.scrollableContent {
    max-height: 200px;
    overflow-y: auto;
  }

.scrollableContent::-webkit-scrollbar {
    width: 12px;
}

.scrollableContent::-webkit-scrollbar-thumb {
    background-color: rgba(155, 155, 155, 0.7);
    border-radius: 20px;
    border: 3px solid transparent;
}

.scrollableContent::-webkit-scrollbar-track {
    background: transparent;
}

.titleContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.titleContainer h1 {
    font-size: 1.4em;
}

.titleContainer .faTimes {
    position: relative;
    top: -10px;
    font-size: 1.8em;
}
  
.wideColumn {
    text-align: center;
    vertical-align: middle; 
    background-color: rgba(121, 28, 28, 0) !important;
    color: #ededee !important;
    font-size: 1.2em !important;
    font-weight: normal ;
    width: 50% !important;
    border-collapse: collapse;
    border: 1px solid white;
  }

  th {
    font-weight: bold !important;;
  }